import React, { useEffect } from 'react';

import ProjectListItem from './ProjectListItem';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver.js';

import ProjectContent from '../data/ProjectContent.json';

const ProjectList = () => {
  const toObserve = [];
  const intersectedEntry = useIntersectionObserver(toObserve, {});

  useEffect(() => {
    if (intersectedEntry.target) {
      intersectedEntry.target.classList.add('in-view');
    }
  });

  return (
    <ul className="project-list">
      {Object.keys(ProjectContent)
        .reverse()
        .map(year => {
          const projects = ProjectContent[year];
          return (
            <li key={`projects-${year}`} className="project-list__year-group">
              <p className="project-list__year">{year}</p>
              <ul>
                {projects.map((proj, projIdx) => (
                  <ProjectListItem
                    ref={ref => toObserve.push(ref)}
                    key={`project-item-${projIdx}--work`}
                    project={proj}
                  />
                ))}
              </ul>
            </li>
          );
        })}
    </ul>
  );
};

export default ProjectList;
