import React from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink';
import ProjectList from '../components/ProjectList';

const WorkArchive = () => (
  <Layout>
    <div className="work__wrapper">
      <div className="header__topper">
        <DynamicLink className="header__name-link" to="/">
          Caitlin Wang —{' '}
        </DynamicLink>
        <h1 className="header__title">Work Archive</h1>
      </div>
      <div className="work-list">
        <ProjectList />
      </div>
    </div>
  </Layout>
);

export default WorkArchive;
