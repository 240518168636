import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import DynamicLink from './DynamicLink.js';

const ProjectListItem = forwardRef(({ id, project }, ref) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <li className="project-list__item" ref={ref} key={id}>
      <div
        className="project-list__item-info"
        onMouseEnter={() => setShowImage(true)}
        onMouseLeave={() => setShowImage(false)}>
        <DynamicLink className="project-list__item-name" to={project.link}>
          {project.name}
        </DynamicLink>{' '}
        &nbsp;
        <span className="project-list__item-details">{project.work}</span>
        <p className="project-list__item-description">{project.description}</p>
      </div>
      <img
        className={`project-list__item-thumbnail ${showImage && 'visible'}`}
        src={project.thumbnailSrc}
        alt={`${project.name} thumbnail`}
      />
    </li>
  );
});

ProjectListItem.propTypes = {
  id: PropTypes.string,
  project: PropTypes.object,
};

ProjectListItem.displayName = 'ProjectListItem';

export default ProjectListItem;
