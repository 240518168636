import { useState, useEffect, useRef } from 'react';

export const useIntersectionObserver = (
  refs,
  { root, rootMargin, threshold },
) => {
  const options = {
    root: root || null,
    rootMargin: rootMargin || '0px',
    threshold: threshold || 0.5,
  };

  const [entry, updateEntry] = useState({});

  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver((entries, observerInstance) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          updateEntry(entry);
          observerInstance.unobserve(entry.target);
        }
      });
    }, options);
  }, []);

  useEffect(() => {
    refs.forEach(ref => observer.current.observe(ref));
    return () => observer.current.disconnect();
  }, refs);

  return entry;
};
